const DoorIconSvg = ({ color = '#363352', width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon/door">
        <path
          id="Rectangle 39591"
          d="M6 3.5H18C18.2761 3.5 18.5 3.72386 18.5 4V20.5H5.5V4C5.5 3.72386 5.72386 3.5 6 3.5Z"
          stroke={color}
        />
        <circle id="Ellipse 12" cx="15" cy="11" r="1" fill={color} />
        <path
          id="Line 4"
          d="M3 20.5L21 20.5"
          stroke={color}
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default DoorIconSvg;
