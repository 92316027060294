import api from '@/services/api';
import Cookies from 'js-cookie';
import moment from 'moment';

//@ts-ignore
const handleaddbasket = async (data) => {
  const arrays = [];
  arrays.push(data._id);
  const push = await api.post('/admin/bucket-add', { listings: arrays });
};
//@ts-ignore
const removebasket = async (data) => {
  const push = await api.post('/admin/delete-basket', { listings: data._id });
};
//@ts-ignore
const AddFavourite = async (id, data) => {
  const basket = await api.post(`/agent/favourite-add/${id}`, data);
};
//@ts-ignore
const RemoveFavourite = async (id, data) => {
  const basket = await api.post(`/agent/favourite-remove/${id}`, {
    listing: data,
  });
};
// import { DateTime } from "luxon";
const getUser = () => {
  if (typeof window !== 'undefined') {
    // const storedUser = localStorage.getItem("brokerterminal");
    // if (!storedUser) return null;

    // return JSON.parse(storedUser);
    const brokerterminal = Cookies.get('userData');
    // console.log("bt", brokerterminal);
    if (!brokerterminal) return null;
    //@ts-ignore
    return JSON.parse(brokerterminal).data;
  } else {
    return null;
  }
};

const calculateDistress = (listing: any): boolean => {
  if (listing?.price && listing?.lastsold) {
    const distress = listing?.price < listing?.lastsold['Amount (AED)'];
    return distress;
  }
  return false;
};
const slugify = function (text: string) {
  return (
    text &&
    text
      .toString()
      .replace('%', 'percent')
      .replaceAll('B/R', 'br')
      .toLowerCase()
      .replaceAll('-', '~')
      .replaceAll(':', '#')
      .replaceAll('/', '|')
      .replaceAll(' ', '-') // replaceAll spaces with -
      .trim()
  );
};
const deSlugify = (text: string) => {
  const arr = text.replace('%', 'percent').replaceAll('-br', ' B/R').split('-');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');

  return str2 && str2.replaceAll('#', ':').replaceAll('~', '-').trim();
};
const deSlugifyName = (text: string) => {
  const arr = text.replace('%', 'percent').split('-');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');

  return str2 && str2.replaceAll('#', ':').replaceAll('~', '-').trim();
};

//@ts-ignore
const calculateQuarterlyIncrease = (data) => {
  let quarterlyChanges = [];
  for (let i = 0; i < data.length; i++) {
    const current = data[i];
    const currentQuarter = current._id;
    const currentPrice = current.price;
    let previousPrice;
    if (i === 0) {
      quarterlyChanges.push({ quarter: currentQuarter, priceChange: 0 });
    } else {
      const previous = data[i - 1];
      // const previousQuarter = previous._id;
      previousPrice = previous.price;
      if (currentQuarter === '2022-Q1') {
        quarterlyChanges.push({ quarter: currentQuarter, priceChange: 0 });
      } else if (currentQuarter === '2022-Q2') {
        const percentageChange =
          ((currentPrice - previousPrice) / previousPrice) * 100;
        quarterlyChanges.push({
          quarter: currentQuarter,
          priceChange: percentageChange,
        });
      } else if (currentQuarter === '2022-Q3') {
        if (!previousPrice) {
          quarterlyChanges.push({ quarter: currentQuarter, priceChange: 0 });
        } else {
          const percentageChange =
            ((currentPrice - previousPrice) / previousPrice) * 100;
          quarterlyChanges.push({
            quarter: currentQuarter,
            priceChange: percentageChange,
          });
        }
      } else if (currentQuarter === '2022-Q4') {
        if (!previousPrice) {
          previousPrice = data[i - 2].price;
        }
        const percentageChange =
          ((currentPrice - previousPrice) / previousPrice) * 100;
        quarterlyChanges.push({
          quarter: currentQuarter,
          priceChange: percentageChange,
        });
      } else if (currentQuarter === '2023-Q1') {
        if (!previousPrice) {
          previousPrice = data[i - 2].price;
        }
        const percentageChange =
          ((currentPrice - previousPrice) / previousPrice) * 100;
        quarterlyChanges.push({
          quarter: currentQuarter,
          priceChange: percentageChange,
        });
      }
    }
  }
  return quarterlyChanges;
};
// const mapBedrooms = (beds) => {
//   if (beds && beds.length > 0) {
//     if (beds.includes("Studio")) {
//       return (
//         "Studio, " +
//         beds
//           .filter((b) => b !== "Studio")
//           .map((br, i) =>
//             i === 0
//               ? br.replace(" B/R", "")
//               : i === beds.length - 2
//               ? " and " + br.replace(" B/R", "")
//               : ", " + br.replace(" B/R", "")
//           )
//           .join("")
//       );
//     } else {
//       return beds
//         .map((br, i) =>
//           i === 0
//             ? br.replace(" B/R", "")
//             : i === beds.length - 1
//             ? " and " + br.replace(" B/R", "")
//             : ", " + br.replace(" B/R", "")
//         )
//         .join("");
//     }
//   } else return "any";
// };
// const removeDuplicates = function (originalArray, prop) {
//   var newArray = [];
//   var lookupObject = {};

//   for (var i in originalArray) {
//     lookupObject[originalArray[i][prop]] = originalArray[i];
//   }

//   for (i in lookupObject) {
//     newArray.push(lookupObject[i]);
//   }
//   return newArray;
// };

// const SortingByDate = function (posts) {
//   return posts.sort((post1, post2) => {
//     const beforeDate = DateTime.fromFormat(post1.createdAt, "LLL dd yyyy");
//     const afterDate = DateTime.fromFormat(post2.createdAt, "LLL dd yyyy");
//     return afterDate - beforeDate;
//   });
// };

const dateFormate = function () {
  var day = new Date().getDate();
  var month = new Date().toLocaleString('en-US', { month: 'long' });
  var year = new Date().getFullYear();

  var todayDate = day + ' ' + month + ',' + ' ' + year;

  return todayDate;
};

// const calculateROIValue = (price, rent) => {
//   return ((rent / price) * 100).toFixed(1);
// };
// const calculatePriceIncreaseYearly = (data) => {
//   // Find the data for the two years we're interested in
//   const year1Data = data.find((d) => d._id === 2021);
//   const year2Data = data.find((d) => d._id === 2022);

//   // Calculate the percentage increase in price
//   const increase = year2Data.price - year1Data.price;
//   const percentIncrease = (increase / year1Data.price) * 100;

//   // Return the result rounded to two decimal places
//   return percentIncrease.toFixed(2);
// };
function convertNumberToPrice(oldprice: number) {
  const price = Number(oldprice ? oldprice.toFixed(1) : oldprice);
  if (price < 1000) {
    return `AED ${price}`;
  } else if (price >= 1000 && price < 1000000) {
    return `AED ${(price / 1000).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}K`;
  } else if (price >= 1000000 && price < 1000000000) {
    return `AED ${(price / 1000000).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}M`;
  } else {
    return `AED ${(price / 1000000000).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}Bn`;
  }
}
function getPriceLabel(price: number) {
  return parseFloat((price / 1000000).toFixed(1)) < 1
    ? 'K'
    : parseFloat((price / 1000000000).toFixed(1)) >= 1
    ? 'Bn'
    : 'M';
}

function NumberFormat(price: number) {
  return new Intl.NumberFormat('en-US').format(Number(price));
}
function NumberFormatFixed(price: number) {
  if (price)
    return new Intl.NumberFormat('en-US').format(Number(price.toFixed(0)));
  else return price;
}

function CalculatePercentChange(prev: number, current: number) {
  if (prev === 0) return '+100%';
  else if (prev === current) return '+0%';
  else {
    const percent = ((current - prev) / prev) * 100;
    if (percent > 0) return '+' + String(percent.toFixed(0)) + '%';
    else return String(percent.toFixed(0)) + '%';
  }
}
function CalculatePercentChangeNumber(prev: number, current: number) {
  const percent = ((current - prev) / prev) * 100;
  return percent.toFixed(1);
}
//@ts-ignore
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
function ValueToDate(timestamp: number) {
  return moment(timestamp).format('YYYY');
}
const mapBedrooms = (beds: string[]) => {
  if (beds && beds.length > 0) {
    if (beds.includes('Studio')) {
      return (
        'Studio, ' +
        beds
          .filter((b) => b !== 'Studio')
          .map((br, i) =>
            i === 0
              ? br.replace(' B/R', '')
              : i === beds.length - 2
              ? ' and ' + br.replace(' B/R', '')
              : ', ' + br.replace(' B/R', '')
          )
          .join('')
      );
    } else {
      return beds
        .map((br, i) =>
          i === 0
            ? br.replace(' B/R', '')
            : i === beds.length - 1
            ? ' and ' + br.replace(' B/R', '')
            : ', ' + br.replace(' B/R', '')
        )
        .join('');
    }
  } else return 'any';
};

//@ts-ignore
function customSortBedsCompany(a, b) {
  // Handle empty strings
  if (a === '') return -1;
  if (b === '') return 1;

  // Convert strings to numbers if possible
  const numA = isNaN(a) ? NaN : parseFloat(a);
  const numB = isNaN(b) ? NaN : parseFloat(b);

  // Handle special cases like "10+"
  if (a === '10+') return -1;
  if (b === '10+') return 1;

  // Compare as numbers or strings
  if (!isNaN(numA) && !isNaN(numB)) {
    return numA - numB;
  } else {
    return a.localeCompare(b);
  }
}

function customSortBedrooms(bedroomsArray: string[]): string[] {
  const regex = /^(\d+)?\s?([a-zA-Z]+)?\s?B\/R$/i;

  return bedroomsArray
    ? bedroomsArray.sort((a, b) => {
        const matchA = a.match(regex);
        const matchB = b.match(regex);

        if (!matchA) return 1; // Put invalid formats at the end
        if (!matchB) return -1; // Put invalid formats at the end

        const [, numA, textA] = matchA;
        const [, numB, textB] = matchB;

        if (textA === 'PENTHOUSE') return 1; // PENTHOUSE should come after numbered B/R
        if (textB === 'PENTHOUSE') return -1; // PENTHOUSE should come after numbered B/R

        if (textA === 'Studio') return -1; // Studio should come before numbered B/R
        if (textB === 'Studio') return 1; // Studio should come before numbered B/R

        // Compare the numeric parts of the strings
        if (numA && numB) {
          const numComparison = parseInt(numA, 10) - parseInt(numB, 10);
          if (numComparison !== 0) return numComparison;
        }

        // If the numeric parts are equal or not present, compare the remaining text parts alphabetically
        return textA.localeCompare(textB);
      })
    : [];
}
//@ts-ignore
const calculateROIValue = (price, rent) => {
  return ((rent / price) * 100).toFixed(1);
};
//@ts-ignore
const calculatePriceIncreaseYearly = (data) => {
  // Find the data for the two years we're interested in
  //@ts-ignore
  const year1Data = data[data.length - 2];
  //@ts-ignore
  const year2Data = data[data.length - 1];

  // Calculate the percentage increase in price
  const increase = year2Data.price - year1Data.price;
  const percentIncrease = (increase / year1Data.price) * 100;

  // Return the result rounded to two decimal places
  return percentIncrease.toFixed(2);
};

const onImageError = (e: any) => {
  e.target.src = '/assets/Images/photo-on-request.png';
};

const customTypesOrder = (a: string, b: string): number => {
  const order: { [key: string]: number } = {
    Apartment: 0,
    Villa: 1,
    Townhouse: 2,
  };

  // If both a and b are in the order object, compare their positions
  if (a in order && b in order) {
    return order[a] - order[b];
  }

  // If only a is in the order, it should come before b
  if (a in order) {
    return -1;
  }

  // If only b is in the order, it should come before a
  if (b in order) {
    return 1;
  }

  // If neither is in the order, maintain their natural alphabetical order
  return a.localeCompare(b);
};

export {
  AddFavourite,
  CalculatePercentChange,
  CalculatePercentChangeNumber,
  NumberFormat,
  NumberFormatFixed,
  RemoveFavourite,
  ValueToDate,
  calculateDistress,
  calculatePriceIncreaseYearly,
  calculateQuarterlyIncrease,
  calculateROIValue,
  convertNumberToPrice,
  customSortBedrooms,
  customSortBedsCompany,
  customTypesOrder,
  dateFormate,
  deSlugify,
  deSlugifyName,
  getPriceLabel,
  getUser,
  handleaddbasket,
  mapBedrooms,
  onImageError,
  onlyUnique,
  removebasket,
  slugify,
};
