import { NumberFormat } from '@/utils';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { listingCardDataInterface } from './AgentMainSaleCardV3';

interface urlFileProps {
  title: string;
  url: string;
}

interface DownloadFilesDialogProps {
  open: boolean;
  onClose: () => void;
  additionalfiles: urlFileProps[];
  cardData: listingCardDataInterface;
}

const DownloadFilesDialog: React.FC<DownloadFilesDialogProps> = ({
  open,
  onClose,
  additionalfiles,
  cardData,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<urlFileProps[]>([]);

  const handleToggle = (file: urlFileProps) => {
    setSelectedFiles((prev) => {
      // Check if the file is already selected
      const isSelected = prev.some(
        (selectedFile) => selectedFile.url === file.url
      );

      // If selected, filter it out; otherwise, add it to the list
      return isSelected
        ? prev.filter((selectedFile) => selectedFile.url !== file.url)
        : [...prev, file];
    });
  };
  const handleDownload = () => {
    selectedFiles.forEach(({ url }) => {
      // Open each file in a new tab to initiate the download
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

      // Optional: close the new window after a few seconds (to prevent lingering empty tabs)
      if (newWindow) {
        setTimeout(() => newWindow.close(), 3000);
      }
    });

    // Close the dialog after initiating downloads
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#2C2C2E',
          color: '#f2f2f2',
          width: '300px',
          borderRadius: '10px',
        },
      }}
    >
      <DialogTitle>Download Files</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup>
            {additionalfiles.map((file, index) => (
              <FormControlLabel
                key={file.url + index}
                control={
                  <Checkbox
                    checked={selectedFiles.some(
                      (selectedFile) => selectedFile.url === file.url
                    )}
                    onChange={() => handleToggle(file)}
                    sx={{
                      color: '#f2f2f2',
                      '&.Mui-checked': {
                        color: '#d12d34',
                      },
                    }}
                  />
                }
                label={
                  <Typography style={{ color: '#f2f2f2' }}>
                    {file.title === 'Payment BreakDown' ||
                    file.title === 'Location on Masterplan'
                      ? cardData?.type + ' ' + cardData.unit + ': ' + file.title
                      : file.title}
                  </Typography>
                }
              />
            ))}
            <Button
              variant="contained"
              color="secondary"
              sx={{
                marginTop: '15px',
                textTransform: 'capitalize',
              }}
              onClick={() => {
                window.location.href = `https://wa.me/${cardData?.user?.phoneNumber.replaceAll(
                  '-',
                  ''
                )}?text=Hi ${cardData.user.firstName},

I have a serious buyer for your ${cardData.bed} ${cardData.type} in ${
                  cardData.project
                } at AED ${NumberFormat(
                  cardData.price
                )}. Is your commission included in this price? If yes, let’s proceed. Please send me the ownership documents so I can arrange the 10% deposit cheque.

Thanks`;

                const text = ``;
              }}
            >
              Request OwnerShip Document
            </Button>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: '#f2f2f2' }}>
          Cancel
        </Button>
        <Button
          onClick={handleDownload}
          disabled={selectedFiles.length === 0}
          color="primary"
          variant="contained"
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadFilesDialog;
