/* eslint-disable react/no-unescaped-entities */
import api from '@/services/api';
import styles from '@/styles/Cards/highEndProjectCard.module.scss';
import { NumberFormat } from '@/utils';
import { MobileContext } from '@/utils/DetectMobileContext';
import { ModalContext } from '@/utils/ModalContext';
import { ImageToBase64, LoaderShimmer } from '@/utils/imageHelpers';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Button, IconButton } from '@mui/material';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';

import ISoldTransactions from '../DTO/ISoldTransaction';
import DialogModals from '../Modals/DialogModals';
import AreaIconSvg from '../SVG/AreaIconSvg';
import DoorIconSvg from '../SVG/DoorIconSvg';
import HomeIconSvg from '../SVG/HomeIconSvg';
import DownloadFilesDialog from './DownloadFilesDialog';

interface urlFileProps {
  title: string;
  url: string;
}
export interface listingCardDataInterface {
  status?: string;
  additionalfiles?: urlFileProps[];
  unit?: string;
  project: string;
  bed: string;
  price: number;
  images: string[];
  developer: string;
  type: string;
  listingType: string;
  location: string;
  count: number;
  createdAt: Date;
  title: string;
  labels: string;
  rankdeveloper: any;
  averages: {
    lastSold: ISoldTransactions;
    count: number;
    averagePrices: number;
    project: string;
    Bedrooms: string;
    developer: string;
    Type: string;
    location: string;
    slicesize: number;
  };
  _id: string;
  slicesize: number;
  lastsold: ISoldTransactions;
  averagePrices: number;
  area: number;
  soldlisting: {
    avgListingPrice: number;
  };

  averagerent: {
    averagePrices: number;
  };
  rentlisting: {
    avgListingPrice: number;
  };
  pricechnages: {
    totalSum: number;
    previousTotalSum: number;
  };
  rentchanges: {
    totalSum: number;
    previousTotalSum: number;
  };
  overview: {
    completionDate: string;
    status: string;
    projectImage: string[];
    developer: string;
    developerImage: string;
    locationImage: string[];
  };
  user: {
    _id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    profilePic: string;
    RealEstateName: string;
    role?: string;
  };
  avgAEDSqf: { avgAEDSqf: number };
  AEDSqf: number;
  reidindata: ISoldTransactions;
}

export interface cardDatas {
  cardDatas: listingCardDataInterface;
  edit?: boolean;
  deleteable?: boolean;
  basket?: boolean;
  slide?: boolean;
  index?: number;
  distress?: boolean;
  posted?: boolean;
  handleAddBasket?: (d: any) => void;
  time?: boolean;
  campaign?: boolean;
  basketsId?: string[];
}
const AgentMainSaleCardV3 = ({
  time = true,
  cardDatas,
  index,
  edit = false,
  basket = false,
  slide = false,
  distress,
  handleAddBasket = (d: any) => {},
  posted = false,
  deleteable = false,
  campaign = false,
  basketsId,
}: cardDatas) => {
  const { isMobile } = useContext(MobileContext);
  const [isInView, setIsInView] = useState(false);
  const router = useRouter();
  const componentRef = useRef(null);
  const [checkHoteDeal, setcheckHoteDeal] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  const [checkDeal, setcheckDeal] = useState(false);
  const [openenq, setopen] = useState(false);
  const [openEdit, setopedit] = useState(false);
  const { data: userSession, status } = useSession();
  const { openModal } = useContext(ModalContext);
  const handleRefresh = async () => {
    const resp = await api.get(`/agent/refresh-listing/${cardDatas._id}`);
    if (resp.data.message === 'success') {
      openModal(
        <DialogModals
          action={() => {}}
          title="Success!"
          type="success"
          label="Continue"
          message={`Successfully refreshed Listing`}
        />
      );
    } else {
      openModal(
        <DialogModals
          action={() => {}}
          title="Error!"
          type="error"
          label="Go Back"
          message={`Failed to refresh Listing`}
        />
      );
    }
  };
  const handleReject = async () => {
    Swal.fire({
      title: 'Add a Reject Reason',
      inputAttributes: {
        autocapitalize: 'on',
      },

      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#d12d34',
      cancelButtonColor: '#161616',
      background: '#2C2C2E',
      color: '#f2f2f2',
      confirmButtonText: 'Reject',
      customClass: {
        confirmButton: 'swal-delete-button-confirm',
        cancelButton: 'red',
      },
      preConfirm: async (login) => {
        try {
          const response = await api.post(
            `/agent/reject-listing/${cardDatas._id}`,
            {
              reason: login,
            }
          );
          // if (response.data.message === 'success') {
          //   return Swal.showValidationMessage(`
          //    Success
          //   `);
          // }
          return 'Success';
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed
          `);
        }
      },
    }).then(async (result) => {
      // if (result.isConfirmed) {
      //   const resp = await api.delete(`/agent/delete-listing/${cardDatas._id}`);
      //   if (resp.data.message === 'success') {
      //     openModal(
      //       <DialogModals
      //         action={() => {}}
      //         title="Success!"
      //         type="success"
      //         label="Continue"
      //         message={`Successfully deleted Listing`}
      //       />
      //     );
      //   } else {
      //     openModal(
      //       <DialogModals
      //         action={() => {}}
      //         title="Error!"
      //         type="error"
      //         label="Go Back"
      //         message={`Failed to delete Listing`}
      //       />
      //     );
      //   }
      // }
      if (result.isConfirmed) {
        openModal(
          <DialogModals
            action={() => {}}
            title="Success!"
            type="success"
            label="Continue"
            message={`Successfully rejected Listing`}
          />
        );
      }
    });
  };
  const handelDelete = async () => {
    Swal.fire({
      title: 'Do you want to delete this Listing?',
      text: `${cardDatas.project} ,${cardDatas.location}, ${cardDatas.bed}-${cardDatas.type}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d12d34',
      cancelButtonColor: '#161616',
      background: '#2C2C2E',
      color: '#f2f2f2',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'swal-delete-button-confirm',
        cancelButton: 'red',
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await api.delete(`/agent/delete-listing/${cardDatas._id}`);
        if (resp.data.message === 'success') {
          openModal(
            <DialogModals
              action={() => {}}
              title="Success!"
              type="success"
              label="Continue"
              message={`Successfully deleted Listing`}
            />
          );
        } else {
          openModal(
            <DialogModals
              action={() => {}}
              title="Error!"
              type="error"
              label="Go Back"
              message={`Failed to delete Listing`}
            />
          );
        }
      }
    });
  };
  const [opentool, setOpentool] = useState(false);

  const handleClosetool = () => {
    setOpentool(false);
  };

  const handleOpentool = () => {
    setOpentool(true);
  };
  useEffect(() => {
    if (
      cardDatas.lastsold &&
      cardDatas.price == cardDatas.lastsold['Amount (AED)']
    ) {
      setcheckDeal(true);
    } else if (
      cardDatas.lastsold &&
      cardDatas.price < cardDatas.lastsold['Amount (AED)']
    ) {
      setcheckHoteDeal(true);
    }
  }, [cardDatas]);
  useEffect(() => {
    const options = {
      root: null, // use the viewport as the root
      rootMargin: '0px',
      threshold: 0.95, // trigger when 50% of the component is visible
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsInView(true);
        observer.disconnect(); // disconnect the observer after the first intersection
      } else {
        setIsInView(false);
        handleClosetool();
      }
    }, options);

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    // Cleanup by disconnecting the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);
  useEffect(() => {
    if (index === 0 && isInView) {
      handleOpentool();
    } else if (!isInView) {
      handleClosetool();
    }
  }, [index, isInView]);
  useEffect(() => {
    if (index === 0 && slide) {
      handleClosetool();
    }
    return () => {
      handleClosetool();
    };
  }, [slide]);
  const agentProfile = cardDatas.user || {
    firstName: 'Ali',
    lastName: 'Hassan',
    phoneNumber: '+971503683775',
    RealEstateName: 'Aaj Property',
    profilePic:
      'https://brokerterminal.sgp1.cdn.digitaloceanspaces.com/5005061.webp',
  };

  const [anchorElt, setAnchorElt] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (cardDatas.lastsold['Property Type'].length > 25)
      setAnchorElt(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorElt(null);
  };

  const opent = Boolean(anchorElt);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const lastAdded =
    Math.abs(new Date().getTime() - new Date(cardDatas.createdAt).getTime()) /
    36e5;

  return (
    <div className={styles.agentCardContainerv3}>
      <div
        className={styles.agentCardContainerv3_top}
        onClick={() =>
          router.push(
            `/listing/seller/${String(
              cardDatas.location ? cardDatas.location : cardDatas.location
            )
              .toLocaleLowerCase()
              .replaceAll(' ', '-')}/${String(
              cardDatas.project ? cardDatas.project : cardDatas.project
            )
              .toLocaleLowerCase()
              .replaceAll(' ', '-')}/${cardDatas._id}`
          )
        }
      >
        <div className={styles.agentCardContainerv3_top_left}>
          <Image
            className={styles.agentCardContainerv3_top_left_image}
            placeholder={`data:image/svg+xml;base64,${ImageToBase64(
              LoaderShimmer(700, 475)
            )}`}
            src={
              cardDatas.images[0] || '/assets/Images/photo-on-request-dark.png'
            }
            alt={cardDatas.project}
            width={700}
            height={700}
          />
          <div className={styles.agentCardContainerv3_top_left_overlay}>
            <div className={styles.agentCardContainerv3_top_left_overlay_top}>
              {time && (
                <div
                  className={
                    styles.agentCardContainerv3_top_left_overlay_top_first
                  }
                >
                  <span>
                    {lastAdded > 24
                      ? String((lastAdded / 24).toFixed(0)) + ' days ago'
                      : String(parseInt(String(lastAdded * 60))) === '0'
                      ? 'Just Now'
                      : lastAdded < 1
                      ? String(parseInt(String(lastAdded * 60))) + ' mins ago'
                      : lastAdded < 24
                      ? String(lastAdded.toFixed(0)) + ' hrs ago'
                      : ''}
                  </span>
                </div>
              )}
              {cardDatas.listingType !== 'buyer' && distress && (
                <div
                  // className="pulse-animation"
                  style={{ position: 'absolute', top: '15px', right: '15px' }}
                >
                  {distress &&
                  Number(
                    (
                      ((cardDatas?.avgAEDSqf?.avgAEDSqf - cardDatas.AEDSqf) /
                        cardDatas.avgAEDSqf.avgAEDSqf) *
                      100
                    ).toFixed(1)
                  ) > 10 ? (
                    <Image
                      src={'/assets/icons/mdi_fire-gold.svg'}
                      alt="icon"
                      width={40}
                      height={40}
                      style={{ marginBottom: '0' }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>

            <div
              className={styles.agentCardContainerv3_top_left_overlay_bottom}
            >
              <div
                className={
                  styles.agentCardContainerv3_top_left_overlay_bottom_first
                }
              >
                <div
                  style={{
                    position: 'relative',
                    width: '36px',
                    height: '36px',
                  }}
                >
                  <Image
                    placeholder={`data:image/svg+xml;base64,${ImageToBase64(
                      LoaderShimmer(700, 475)
                    )}`}
                    src={
                      agentProfile?.profilePic || '/assets/Images/user_icon.png'
                    }
                    alt={agentProfile?.firstName[0]}
                    width={100}
                    height={100}
                  />
                  <Image
                    alt=""
                    src={'/assets/Images/approve.png'}
                    width={10}
                    height={10}
                    style={{
                      width: '10px',
                      height: '10px',
                      position: 'absolute',
                      right: '-5px',
                      bottom: '1px',
                      background: 'transparent',
                      border: 'none',
                    }}
                  />
                </div>

                <section>
                  <span>
                    {agentProfile?.firstName} {agentProfile?.lastName}
                  </span>
                  {/* <p>
                    (Rank No. {cardDatas?.rankdeveloper?.rank} in Secondary
                    Market)
                  </p> */}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.agentCardContainerv3_bottom}
        style={{ position: 'relative' }}
      >
        {cardDatas.status === 'REJECTED' && (
          <div
            style={{
              position: 'absolute',
              background: '#d12d34',
              height: '40px',
              right: '0',
              top: '-25px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px 20px',
            }}
          >
            <span>Rejected</span>
          </div>
        )}
        <div className={styles.agentCardContainerv3_bottom_labels}>
          <button>
            {cardDatas.listingType === 'seller' ? 'For Sale' : 'Required'}
          </button>
          {cardDatas.labels === 'Exclusive' && (
            <button>
              {cardDatas.labels}
              <VerifiedIcon
                color="secondary"
                sx={{
                  fontSize: '14px',
                }}
              />
            </button>
          )}
          {cardDatas.reidindata && (
            <button>
              {cardDatas.reidindata?.SaleType.includes('Ready')
                ? 'Completed'
                : cardDatas.reidindata.SaleType.includes('Off')
                ? 'Off Plan'
                : ''}
            </button>
          )}
          {distress &&
            cardDatas?.avgAEDSqf &&
            cardDatas.listingType === 'seller' && (
              <button
                className="golden-gradient"
                style={{
                  // background:
                  //   'linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)',
                  color: '#000',
                }}
              >
                {(
                  ((cardDatas?.avgAEDSqf?.avgAEDSqf - cardDatas.AEDSqf) /
                    cardDatas.avgAEDSqf.avgAEDSqf) *
                  100
                ).toFixed(1)}
                % Below Avg
              </button>
            )}
          {cardDatas.unit && (
            <button>
              {cardDatas.type === 'Villa'
                ? 'Villa No:'
                : cardDatas.type === 'Apartment'
                ? 'Apt '
                : cardDatas.type === 'Townhouse'
                ? 'TH '
                : cardDatas.type === 'Penthouse'
                ? 'PH '
                : ''}{' '}
              {cardDatas.unit}
            </button>
          )}
        </div>
        <div
          className={styles.agentCardContainerv3_bottom_address}
          style={{
            position: 'relative',
          }}
        >
          <h3>{cardDatas.project}</h3>
          <span className={styles.agentCardContainerv3_bottom_address_location}>
            <Image
              src={'/assets/icons/map-pin.svg'}
              height={18}
              width={18}
              alt="location"
            />{' '}
            {cardDatas.location}
          </span>
          {cardDatas?.additionalfiles &&
            cardDatas?.additionalfiles.length > 0 && (
              <IconButton
                onClick={openDialog}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
              >
                <CloudDownloadIcon sx={{ color: '#f2f2f2' }} />
              </IconButton>
            )}
          {cardDatas?.additionalfiles &&
            cardDatas?.additionalfiles.length > 0 && (
              <DownloadFilesDialog
                open={isDialogOpen}
                onClose={closeDialog}
                additionalfiles={cardDatas?.additionalfiles}
                cardData={cardDatas}
              />
            )}
          <div
            className={styles.agentCardContainer_main_details_numbers}
            style={{ width: isMobile ? '100%' : '50%', marginTop: '6px' }}
          >
            <span style={{ color: '#F2F2F2', fontSize: '13px' }}>
              <DoorIconSvg color="#F2F2F2" width={20} height={20} />{' '}
              {cardDatas.bed}
            </span>

            {cardDatas.type && (
              <span>
                <HomeIconSvg color="#F2F2F2" width={20} height={20} />{' '}
                <span
                  style={{
                    color: '#F2F2F2',
                    fontSize: '13px',
                    width: cardDatas.type.includes('Service') ? '100px' : '',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {' '}
                  {cardDatas.type}
                </span>
              </span>
            )}
            <span style={{ color: '#F2F2F2', fontSize: '13px' }}>
              <AreaIconSvg color="#F2F2F2" width={20} height={20} />
              {
                //@ts-ignore
                NumberFormat(cardDatas.area.toFixed(0))
              }{' '}
              Sq.ft
            </span>
          </div>

          {cardDatas.title && cardDatas.title !== '' ? (
            <h5
              style={{
                fontSize: '12px',
                color: '#969696',
                width: '100%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {cardDatas.title}
            </h5>
          ) : (
            ''
          )}
        </div>
        <div
          className={styles.agentCardContainerv3_bottom_address_price}
          style={{ position: 'relative' }}
        >
          <span>
            AED <span>{NumberFormat(cardDatas.price).replace('AED', '')}</span>
          </span>

          {userSession?.user?.role.includes('ADMIN') && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: -10,
              }}
            >
              <IconButton size="large" onClick={handleReject}>
                <ThumbDownIcon
                  color="secondary"
                  sx={{
                    fontSize: '35px',
                  }}
                />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      {campaign && (
        <div style={{ padding: '0 16px 10px', display: 'flex', gap: '12px' }}>
          <Button
            variant="contained"
            onClick={() => {
              handleAddBasket(cardDatas);
            }}
            style={{
              borderRadius: '32px',
              flexGrow: 1,
              width: '100%',
            }}
          >
            {basketsId?.includes(cardDatas._id)
              ? 'Remove from Campaign'
              : 'Add to Campaign'}
          </Button>
        </div>
      )}
      {!posted &&
        ((edit && deleteable) || userSession?.user?.role.includes('ADMIN')) && (
          <div style={{ padding: '0 16px 10px', display: 'flex', gap: '12px' }}>
            <Button
              variant="contained"
              onClick={() => {
                router.push(`/listing/edit-listing/${cardDatas._id}`);
              }}
              style={{
                borderRadius: '32px',
                flexGrow: 1,
                width: '100%',
              }}
            >
              Edit
            </Button>
            <Button
              style={{
                borderRadius: '32px',
                flexGrow: 1,
                width: '100%',
              }}
              variant="outlined"
              onClick={handelDelete}
            >
              Delete
            </Button>
            <IconButton
              style={{
                borderRadius: '32px',
                flexGrow: 1,
              }}
              onClick={handleRefresh}
            >
              <RefreshIcon
                sx={{
                  color: '#3043F0',
                }}
              />
            </IconButton>
          </div>
        )}
    </div>
  );
};

export default AgentMainSaleCardV3;
